/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
html {
	font-size: 62.5%; /* Changes default font size to 10px = 1rem */
}

body {
  font: $font-weight #{$font-size}/#{$line-height} $font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
     -moz-font-feature-settings: "kern" 1;
       -o-font-feature-settings: "kern" 1;
          font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $unit*1.5;
}



/**
 * `main` element
 */
main {
  display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
	color: $brand-color;
  font-weight: $font-bold;
}


/**
 * Links
 */
a {
  color: $brand-color;
  text-decoration: none;

  &:visited {
    color: darken($brand-color, 15%);
  }

  &:hover {
    color: $text-color;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}
p a {
  text-decoration: underline;
}



/**
 * Buttons
 * */
button,
a.button,
button.button,
submit,
submitbutton,
input.button,
[type="button"],
[type="reset"],
[type="submit"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
  background-color: $button-color;
	border: 0;
	color: $white;
	cursor: pointer;
	display: inline-block;
	font-size: $font-size;
	padding: ($unit*1.6) ($unit*2.4);
	text-decoration: none;
	white-space: normal;
	width: auto;
	-webkit-appearance: inherit;
}
button:hover,
a.button:hover,
button.button:hover,
submit:hover,
submitbutton:hover,
input.button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover,
button:focus,
a.button:focus,
button.button:focus,
submit:focus,
submitbutton:focus,
input.button:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
    background-color: $button-hover;
}


/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}
.bg-stretch {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	
	@include media-query($on-600) {
		min-height: 200px;
	}
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

/**
 * Forms
 */
input,
select,
textarea {
	border: none;
	color: $text-color;
	font-family: $font-family;
	font-size: $font-size;
	margin-bottom: $unit*2;
	padding: $unit*2;
	width: 100%;
}
form {
	input {
		margin-bottom: $unit*3;
	}
}
input[type="checkbox" i] {
	width: auto;
}
select {
	max-width: calc(100% - #{$unit * 1});
}
textarea {
	min-height: $unit * 10;
}


/**
 * Lists
 */
ul, ol {
  margin-left: $unit*1.6;
	li {
		list-style-type: square;
		margin-bottom: $unit/2;
	}
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}
ul.list {
	margin: 0;
	
	@extend %clearfix;
}
ul.list li {
	list-style: none;
	float: left;
	margin-bottom: $unit*3;
}
li h3 {
	margin: ($unit*0.8) 0 0;
}

li.full {
	width: 100%;
}
li.half {
	width: 48.5%;
	margin-right: 3%;
	
	&:nth-child(2n+2) { margin-right: 0; }
	
	@include media-query($on-mobile) {
		width: 100%;
		margin-right: 0;
	}
}
li.third {
	width: 31.3%;
	margin-right: 3%;
	
	&:nth-child(3n+3) { margin-right: 0; }
	
	//*@include media-query($on-laptop) {
		//*width: 48.5%;
		//*&:nth-child(3n+3) { margin-right: 3%; }
		//*&:nth-child(2n+2) { margin-right: 0; }
	//*}*//
	@include media-query($on-600) {
		width: 100%;
		margin-right: 0;
	}
}
li.two-thirds {
	width: 65.7%;
	margin-right: 3%;
	
	@include media-query($on-mobile) {
		width: 100%;
		margin-right: 0;
	}
}
li.quarter {
	width: 22.7%;
	margin-right: 3%;
	
	&:nth-child(4n+4) { margin-right: 0; }
	
	@include media-query($on-1000) {
		width: 48.5%;
		&:nth-child(3n+3) { margin-right: 3%; }
		&:nth-child(2n+2) { margin-right: 0; }
	}
	@include media-query($on-500) {
		width: 100%;
		margin-right: 0;
	}
}

/* Special list widths */
li.third-to-half {
	width: 31.3%;
	margin-right: 3%;
}
li.two-thirds-to-half {
	width: 65.7%;
	margin-right: 3%;
}
li.third-to-half,
li.two-thirds-to-half {
	@include media-query($on-laptop) {
		width: 48.5%;
	}
	@include media-query($on-mobile) {
		width: 100%;
		margin-right: 0;
	}
}

li.last {
	margin-right: 0;
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey;
  border-left: 4px solid $grey-light;
  padding-left: $unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Containers
 */
.container {
  width: $content-width;
  @extend %clearfix;
	
	.wrapper {
		padding: $unit*8 $unit*4;
		@include media-query($on-800) {
		  padding: $unit*4 $unit*2;
		}
	}
}
.left-xl {
	display: flex;
	
	div.col-1 {
		display: flex;
		width: 67%;
		
		.wrapper {
			align-self: center;
		}
		@include media-query ($on-palm) {
			display: block;
			width: 100%;
		}
	}
	div.col-2 {
		display: flex;
		width: 33%;
		
		.wrapper {
			align-self: center;
		}
		
		@include media-query ($on-palm) {
			display: block;
			width: 100%;
		}
	}
	@include media-query ($on-palm) {
			display: block;
		}
}
.right-xl {
	display: flex;
	
	div.col-1 {
		display: flex;
		width: 33%;
		
		.wrapper {
			align-self: center;
		}
		@include media-query ($on-palm) {
			display: block;
			width: 100%;
		}
	}
	div.col-2 {
		display: flex;
		width: 67%;
		
		.wrapper {
			align-self: center;
		}
		
		@include media-query ($on-palm) {
			display: block;
			width: 100%;
		}
	}
	@include media-query ($on-palm) {
			display: block;
		}
}

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$on-1200} - (#{$unit}*4));
  max-width:         calc(#{$on-1200} - (#{$unit}*4));
  margin-right: auto;
  margin-left: auto;
  padding: $unit*4;
  @extend %clearfix;

  @include media-query($on-800) {
    max-width: -webkit-calc(#{$content-width} - (#{$unit}*2));
    max-width:         calc(#{$content-width} - (#{$unit}*2));
    padding: $unit*2;
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: #{$grey};
    padding-right: 5px;
    vertical-align: text-top;
}

.social-media-list {
  li + li {
    padding-top: 5px;
  }
}



/**
 * Tables
 */
table {
  margin-bottom: $unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-light;
  tr {
    &:nth-child(even) {
      background-color: lighten($grey-light, 6%);
    }
  }
  th, td {
    padding: ($unit / 3) ($unit / 2);
  }
  th {
    background-color: lighten($grey-light, 3%);
    border: 1px solid darken($grey-light, 4%);
    border-bottom-color: darken($grey-light, 12%);
  }
  td {
    border: 1px solid $grey-light;
  }
}
