/**
 * Site header
 */
.site-header {
  background-color: $brand-color;
  color: $background-color;
  height: $unit*8;

  // Positioning context for the mobile navigation icon
  position: relative;
  
  svg {
    path {
      fill: $background-color;
    }
  }
  .wrapper {
    padding-bottom: $unit*2;
    padding-top: $unit*2;
  }
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: 0;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $grey-dark;
  }
  svg {
    max-height: $unit*3;
    padding-top: 5px;
    width: auto;
  }
}

.site-nav {
  float: right;

  /* Menu Icon */
  .nav-trigger {
      display: none;
  }
  .menu-icon {
    display: none;
  }
  
  /* Menu */
  ul {
    margin-left: 0;
    li {
      list-style: none;
    }
  }
  li:hover {
    cursor: pointer;
  }
  .menu {
    float: right;
    margin-top: 5px;
  }
  li.page-link {
    color: $background-color;
    font-weight: $font-bold;
    float: left;
    position: relative;
    &:hover,
    &:focus {
      color: $blue-light;
    }
    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
    a {
      color: $background-color;
      font-weight: $font-bold;
      line-height: $line-height;      
      &:hover,
      &:focus {
        color: $blue-light;
      }
    }
  }
  
  /* Sub Menu */
  .sub-link {
      margin: 0 20px;
      padding: 5px 10px;
  }
  .sub-menu {
    background-color: $brand-color;
    left: -20px;
    position: absolute;
    top: 25px;
    z-index: 10;
  }
  .sub-trigger {
    display: none;
  }
  label[for="sub-trigger"] {
    cursor: pointer;
    display: block;
    position: relative;
  }
  input ~ .sub-menu {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
  input:checked ~ .sub-menu {
    display: block;
    opacity: 1;
    visibility: visible;
    padding-bottom: 5px;
  }

  
  @include media-query($on-900) {
    /* Menu */
    position: absolute;
    top: 9px;
    right: $unit / 2;
    background-color: $brand-color;
    text-align: right;
    z-index: 10;

    /* Menu Icon */
    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }
    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 20px;
      text-align: center;
      > svg {
        fill: $grey-dark;
      }
    }

    /* Menu */
    .menu {
      margin-top: 20px;
    }
    input ~ .menu {
      clear: both;
      display: none;
    }
    input:checked ~ .menu {
      display: block;
      padding-bottom: 5px;
    }
    li.page-link {
      display: block;
      float: none;
      margin: 0 20px;
      padding: 5px 10px;
      &:not(:last-child) {
        margin: 0 20px;
      }
    }
    .sub-menu {
      left: auto;
      right: 100px;
      top: 0;
    }
  }
  @include media-query ($on-600) {
    li.page-link {
      margin: 0 10px;
      &:not(:last-child) {
        margin-right: 10px;
        margin: 0 10px;
      }
    }
  }
  .sub-link {
    margin: 0 10px;
  }
}

/* Intro Header */

.intro-header {
  background: none;
  color: $white;
  margin-top: 34px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 5;
  
  svg {
    path {
      fill: $white;
    }
  }
  li.page-link {
    color: $white;
    a {
      color: $white; 
    }
  }
  .sub-menu {
    background: none;
  }
  @include media-query ($on-900) {
    .site-nav {
      background: none;
    }
    .menu {
    background-color: $brand-color;      
    }
    .sub-menu {
      background-color: $brand-color;
    }
  }
  @include media-query ($on-600) {
    background-color: $brand-color;
    color: $background-color;
    margin-top: 0;
    position: relative;
    
  }
}

/**
 * Preheader
 */
.preheader {
  background-color: $footer-color;
  color: $white;
  text-align: right;
  
  .wrapper {
    padding-bottom: $unit/2;
    padding-top: $unit/2;
    
    p {
      margin: 0;
    }
  }
}

