/* Prefooter */
.prefooter {
  img {
    height: auto;
    margin-right: 20px;
    width: 40px;
  }
}

/**
 * Site footer
 */
.site-footer {
  background-color: $footer-color;
  color: $white;
  padding: $unit 0;
  li {
    list-style: none;
  }
  a {
    color: $white;
  
    &.page-link {
      display: block;
    }
    &:hover,
    &:focus {
      color: $grey-light;
    }
  }
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $unit / 2;
}

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0;
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  margin-left: -$unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin: 0 $unit $unit 0;
}

.footer-col-1,
.footer-col-2 {
  width: -webkit-calc(33% - #{$unit});
  width:         calc(33% - #{$unit});
}
.footer-col-3 {
  margin-right: 0;
  width: 33%;
}

.footer-credit {
  margin-left: 0;
}

@include media-query($on-800) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (#{$unit}));
    width:         calc(50% - (#{$unit}));
  }

  .footer-col-3 {
    width: 100%;
  }
}

@include media-query($on-600) {
  .footer-col {
    float: none;
    width: 100%;
  }
}

/* Prefooter */
.prefooter {
  svg {
    width: 30px;
    height: auto;
  }
}
