@charset "utf-8";

// Define defaults for each variable.

$font-family:       Helvetica, Arial, sans-serif !default;
$font-size:         16px !default;
$font-weight:       400 !default;
$font-bold:         700;
$small-font-size:   $font-size * 0.875 !default;
$line-height:       1.4 !default;

$unit:              10px !default;

$black:             #000;
$white:             #fff;
$grey:              #4E4D5C !default;
$grey-light:        #8B8A97 !default;
$grey-dark:         #302F3B !default;
$blue:              #031756;
$blue-light:        #0C2883;
$blue-dark:         #000618;
$magnolia:          #F9F5FF;

$text-color:        $grey-dark !default;
$background-color:  $magnolia !default;
$footer-color:      $blue-dark;
$brand-color:       $blue !default;

$button-color:      $brand-color;
$button-hover:      $blue-light !default;

$table-text-align:  left !default;

// Width of the content area
$content-width:     100% !default;

$on-mobile:         300px !default;
$on-palm:           600px !default;
$on-laptop:         1000px !default;
$on-1200:           1200px;
$on-1100:           1100px;
$on-1000:           1000px;
$on-900:            900px;
$on-800:            800px;
$on-700:            700px;
$on-600:            600px;
$on-500:            500px;
$on-400:            400px;
$on-300:            300px;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $font-size * $ratio;
}

// Import partials.
@import
  "brooks/base",
  "brooks/header",
  "brooks/footer",
  "brooks/layout",
  "brooks/syntax-highlighting"
;
