small,
.small {
  font-size: $small-font-size;
}

.textcenter {
  margin: 0 auto;
  text-align: center;
}

.important {
  color: #dc0000;
}

.hero {
  color: $white;
  @include media-query($on-600) {
    max-height: calc(100vh - 109px);      
  }
  
  h1,h2,h3,h4,h5 {
    color: $white;
    font-weight: $font-weight;
  }
  .shade {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 29px);
    justify-content: center;
    position: relative;
    @include media-query($on-600) {
      max-height: calc(100vh - 109px);      
    }
    
    .button {
      background: none;
      border: 1px solid $white;
      color: $white;
      display: inline-block;
      margin-top: 20px;
      &:hover,
      &:focus {
        border-color: $blue-light;
        color: $blue-light;
      }
    }
    .fas {
      bottom: 20px;
      color: $white;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      width: 20px;
    }
  }
}


/**
 * Page content
 */
.page-content {
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    margin-bottom: $unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $unit;
  min-height: 340px;
  
  .wrapper {
    background: rgba(0,0,0,0.5);
    max-width: 100%;
    
    
    h1 {
      margin: 0 auto;
      max-width: -webkit-calc(#{$on-1200} - (#{$unit}*4));
      max-width:         calc(#{$on-1200} - (#{$unit}*4));

      @include media-query($on-800) {
        max-width: -webkit-calc(#{$content-width} - (#{$unit}*2));
        max-width:         calc(#{$content-width} - (#{$unit}*2));
      }
    }
  }
}
.text-page .post-header {
  min-height: 0;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: $unit*3;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
  position: relative;
  
  .wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .post-title {
    margin-bottom: 0;
  }
  h1 {
    color: $white;
  }
}

.post-content {
  margin-bottom: $unit;

  h2 {
    @include relative-font-size(2);

    @include media-query($on-laptop) {
      @include relative-font-size(1.75);
    }
  }

  h3 {
    @include relative-font-size(1.625);

    @include media-query($on-laptop) {
      @include relative-font-size(1.375);
    }
  }

  h4 {
    @include relative-font-size(1.25);

    @include media-query($on-laptop) {
      @include relative-font-size(1.125);
    }
  }
  .button {
    margin-top: $unit*2;
  }
}

/**
 * Featured Images
 */
.featured-image {
  display: block;
  height: auto;
  width: 100%;
  
  @include media-query($on-600) {
    display: none;
  }
}
.featured-image-short {
  display: none;
  height: auto;
  width: 100%;
  
  @include media-query($on-600) {
    display: block;
  }
}

.inverse {
  background-color: $brand-color;
  color: $magnolia;
  h1,h2,h3,h4,h5 {
    color: $magnolia;    
  }
}

.order-book {
  h1 {
    text-align: center;
  }
}

/* Forms */

.booking-form {
  margin: 0 auto;
  max-width: 800px;
  
  .form-left {
    float: left;
    margin-right: 5%;
    width: 45%;
  }
  .form-right {
    float: right;
    margin-left: 5%;
    width: 45%;
  }
  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea {
    background: $background-color;
    border: 1px solid #d7d7d7;
    margin-top: 10px;
    padding: 15px;
    width: 100%;
    @include media-query ($on-600) {
      max-width: calc(100% - 32px);
    }
  }
  input[type="submit"] {
    display: block;
    margin: 0 auto 30px;
  }
  input[type="checkbox"] {
    margin-bottom: 0;
  }
  @include media-query ($on-600) {
    .form-left,
    .form-right {
      float: none;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

.contact-form {
  margin: 0 auto;
  max-width: 800px;
  
  .form-left {
    float: left;
    margin-right: 5%;
    width: 45%;
  }
  .form-right {
    float: right;
    margin-left: 5%;
    width: 45%;
  }
  input[type="text"],
  input[type="email"],
  input[type="date"],
  textarea {
    background: $background-color;
    border: 1px solid #d7d7d7;
    margin-top: 10px;
    padding: 15px;
    width: 100%;
    @include media-query ($on-600) {
      max-width: calc(100% - 32px);
    }
  }
  input[type="submit"] {
    display: block;
    margin: 0 auto 30px;
  }
  input[type="checkbox"] {
    margin-bottom: 0;
  }
  @include media-query ($on-600) {
    .form-left,
    .form-right {
      float: none;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }
}

.order-track {
  background-image: url('/assets/images/global-map.png');
  display: flex;
  align-items: center;
  color: $white;
  height: 50vh;
  min-height: 500px;
  h1 {
    color: $white;    
  }
  input[type="text"] {
    padding: 15px;
    width: auto;
  }
  &.order-track-page {
    min-height: 400px;
  }
}

.shade {
  background-color: rgba(0,0,0,0.7);
  height: 100%;
  width: 100%;
  &.header-shade {
    background-color: rgba(0,0,0,0.57);
  }
}

.services {
  align-items: center;
  color: $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    color: $white;
    &:hover,
    &:focus {
      color: $white; 
    }
  }
  h2 {
    color: $white;
  }
  .wrapper {
    padding: 200px 40px;
  }
  .shade {
    &:hover,
    &:focus {
      background-color: rgba(3,23,86,0.8);
    }
  }
  .quarter {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25%;
    &.first {
      background-image: url('/assets/images/brooks-473418522-600.jpg');      
    }
    &.second {
      background-image: url('/assets/images/brooks-60549216-600.jpg');      
    }
    &.third {
      background-image: url('/assets/images/brooks-537724-600.jpg');      
    }
    &.fourth {
      background-image: url('/assets/images/brooks-2019319-600.jpg');      
    }
    .button {
      display: inline-block;
      margin: 10px auto 0;
    }
    @include media-query ($on-900) {
      width: 50%;
      .wrapper {
        padding: 100px 40px;
      }
    }
    @include media-query ($on-500) {
      width: 100%;
      .wrapper {
        padding: 150px 40px;
      }
    }
  }
}

.social-network {
  h1 {
    margin-bottom: $unit*3;
  }
  ul {
    border-bottom: 1px solid #d7d7d7;
    padding-bottom: $unit*5;
  }
}

.information img {
  display: block;
  margin: 0 auto 20px;
  max-width: 400px;
  width: 100%;
}

.more-info {
  img {
    display: block;
    margin: 0 auto $unit*2;
    max-height: 80px;
  }
}